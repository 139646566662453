.DealModule-container-parent-class .pipeline__module__wrapper {
  background: #fff;
  margin: 0.5rem 8px 1rem 8px;
  padding: 20px 10px;
  border-radius: 10px !important;
}
.DealModule-container-parent-class
  .pipeline__module__wrapper
  .pipeline__main__container
  #cutomScrollbar1 {
  overflow-x: auto;
}
.DealModule-container-parent-class
  .pipeline__module__wrapper
  .pipeline__main__container {
  /* overflow-x: auto ; */
  border-radius: 0px !important;
}
.DealModule-container-parent-class
  .pipeline__module__wrapper
  .each__pipeline__container__v2
  li {
  margin-left: 0;
  width: 100%;
  max-width: calc(100% - 25px);
  height: 90px;
  list-style-type: none;
  padding: 0;
  border-right: 3px solid white;
  position: relative;
  margin-bottom: 0px;
  display: flex;
  width: 100%;
  align-items: center;
}
.DealModule-container-parent-class
  .pipeline__module__wrapper
  .each__pipeline__container__v2
  li:after {
  z-index: 1;
  position: absolute;
  top: 0;
  right: -25px;
  display: block;
  border-left: 25px solid;
  border-top: 45px solid transparent;
  border-bottom: 45px solid transparent;
  width: 0;
  height: 0;
  content: " ";
}
.DealModule-container-parent-class
  .pipeline__module__wrapper
  .each__pipeline__container__v2
  li:before {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  border-left: 25px solid white;
  border-top: 45px solid transparent;
  border-bottom: 45px solid transparent;
  width: 0;
  height: 0;
  content: " ";
}
.DealModule-container-parent-class
  .pipeline__module__wrapper
  .pipeline_stage_top_wrapper:first-child
  .each__pipeline__container__v2
  li:before {
  display: none;
}
.DealModule-container-parent-class
  .pipeline__module__wrapper
  .pipeline_stage_top_wrapper:last-child
  .each__pipeline__container__v2
  li:after {
  display: none;
}
.DealModule-container-parent-class
  .pipeline__module__wrapper
  .pipeline_stage_top_wrapper:last-child
  .each__pipeline__container__v2
  li {
  max-width: 100%;
}
.DealModule-container-parent-class
  .pipeline__module__wrapper
  .pipeline_stage_top_wrapper
  .each__pipeline__header__container {
  padding-left: 40px !important;
}
.DealModule-container-parent-class
  .pipeline__module__wrapper
  .each__pipeline__title {
  background: #ffffff;
  border-radius: 5px;
  padding: 3px 18px;
  display: block;
  color: #133159;
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;
  margin-bottom: 7px;
  letter-spacing: 0.01em;
}
.DealModule-container-parent-class
  .pipeline__module__wrapper
  .pipeline_stage_top_wrapper:first-child
  .each__pipeline__container__v2
  .each__pipeline__header__container {
  padding-left: 15px !important;
}
.DealModule-container-parent-class
  .pipeline__module__wrapper
  .pipeline_stage_top_wrapper
  .each__pipeline__header__container {
  width: calc(100% - 10px);
}
.DealModule-container-parent-class
  .pipeline__module__wrapper
  .pipeline_stage_top_wrapper {
  min-width: 290px;
}
.DealModule-container-parent-class
  .pipeline__module__wrapper
  .each__pipeline__count {
  display: flex;
  grid-gap: 20px;
}
.DealModule-container-parent-class
  .pipeline__module__wrapper
  .each__pipeline__count__top {
  display: flex;
  align-items: center;
  grid-gap: 10px;
}
.DealModule-container-parent-class
  .pipeline__module__wrapper
  .each__pipeline__count__top__contant {
  background: #ffffff;
  border-radius: 5px;
  padding: 4px 18px;
  position: relative;
  font-weight: 400 !important;
  font-family: "Poppins";
  display: inline-block;
  font-size: 13px;
}
.DealModule-container-parent-class .pipeline__module__wrapper .dot__separator {
  width: 6px;
  height: 6px;
  /* background: rgb(216, 35, 35); */
  display: block;
  border-radius: 50%;
}
.DealModule-container-parent-class
  .pipeline__module__wrapper
  .each__pipeline__count__bottom__total {
  background: #ffffff;
  border-radius: 5px;
  padding: 4px 18px;
  position: relative;
  font-weight: 400 !important;
  font-family: "Poppins";
  display: inline-block;
  font-size: 13px;
}

.DealModule-container-parent-class
  .pipeline__module__wrapper
  .each__pipeline__description {
  box-shadow: none;
  transition: none;
  margin: 0 8px 0 0;
  padding: 3px 13px 1px 8px;
  background: #f6f7fb;
  border: 1px solid #e5e8ef;
  box-sizing: border-box;
  border-radius: 10px;
  height: 100%;
  max-height: 590px;
  width: 100% !important;
  height: calc(100% - 120px);
}
.DealModule-container-parent-class
  .pipeline__module__wrapper
  .each__pipeline__action__row {
  margin: 10px 0 8px 0;
  background-color: rgb(55, 107, 63);
  padding-bottom: 5px;
  border-top-right-radius: 13px;
  border-top-left-radius: 13px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.DealModule-container-parent-class
  .pipeline__module__wrapper
  .each__pipeline__action__row__card {
  background: #fff;
  border-radius: 10px;
  padding: 6px 10px 0;
}
.DealModule-container-parent-class
  .pipeline__module__wrapper
  .each__pipeline__action__row__card
  .each__title {
  color: #333333;
  font-weight: 500;
  letter-spacing: 0.01em;
  line-height: 24px;
  width: 100%;
  margin: 20px 0 10px !important;
  border: 1px solid #e5e8ef;
  box-sizing: border-box;
  border-radius: 5px;
  justify-content: center;
  font-family: Poppins;
  align-items: center;
  font-size: 13px;
  display: inline-block;
  padding: 5px 10px;
  cursor: pointer;
}
.DealModule-container-parent-class
  .pipeline__module__wrapper
  .each__pipeline__action__single__i {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.DealModule-container-parent-class
  .pipeline__module__wrapper
  .each__pipeline__action__single__div {
  display: inline-flex;
  align-items: center;
  grid-column-gap: 10px;
  column-gap: 10px;
}
.DealModule-container-parent-class
  .pipeline__module__wrapper
  .each__pipeline__action__single__percent {
  background: #ffffff !important;
  border: 1px solid #e5e8ef !important;
  box-sizing: border-box;
  border-radius: 5px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 28px !important;
  text-align: center;
  display: inline-block;
  min-width: 50px;
  max-width: 50px;
  min-height: 30px;
  align-items: center;
  margin: 0;
  color: #546376;
}
.DealModule-container-parent-class
  .pipeline__module__wrapper
  .each__pipeline__action__button {
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #e5e8ef;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  position: relative;
}
.DealModule-container-parent-class
  .pipeline__module__wrapper
  .each__pipeline__action__button__bg {
  background-color: #e5e8ef !important;
}
.DealModule-container-parent-class
  .pipeline__module__wrapper
  .each__pipeline__action__border__red {
  border-color: #ff264a;
}

.disalbe_option_view {
  cursor: not-allowed !important;
  opacity: 0.5;
}
.disalbe_option_view .each__pipeline__action__button {
  cursor: not-allowed !important;
}
.DealModule-container-parent-class .dp_asp_alt {
  max-width: unset !important;
  width: unset !important;
  flex: 1 !important;
  margin-right: 10px !important;
  padding-right: 10px !important;
}
.DealModule-container-parent-class
  .dp_asp_alt
  .MuiFormControl-root.alt.customFormControl {
  width: unset !important;
}
.DealModule-container-parent-class
  .dp_asp_alt
  .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiInputBase-input.MuiInput-input {
  padding-right: 20px !important;
}

.DealModule-container-parent-class
  .dp_asp_alt
  .MuiInputBase-root.MuiInput-root.MuiInput-underline.trigger__select.pipeline-select.MuiInputBase-formControl.MuiInput-formControl {
  max-width: unset !important;
}

/* scroll bar css */
.scrollBar {
  padding-bottom: 4px;
  overflow-y: auto;
  box-sizing: border-box;
}
/* .scrollBar::-webkit-scrollbar:hover{
    width: 10px;
  } */
.scrollBar::-webkit-scrollbar {
  width: 6px;
  height: 3px;
}
.scrollBar::-webkit-scrollbar-track {
  background: rgba(60, 126, 243, 0.1);
  border-radius: 4px;
}
.scrollBar::-webkit-scrollbar {
  scrollbar-width: thin;
}
.scrollBar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #949db2;
  box-sizing: border-box;
}
.scrollBar::-webkit-scrollbar-track {
  background: #f4f5f7;
  box-shadow: inset 1px 0px 0px rgba(148, 157, 178, 0.12);
  -webkit-box-shadow: inset 1px 0px 0px rgba(148, 157, 178, 0.12);
}
