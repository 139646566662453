@media only screen and (max-width:1919px) {
  .deal-catagory-section ul li {
    margin-right: 7px;
  }
  .deal-catagory-section ul li span.deal-title {
    margin-right: 5px;
  }
  .deal-catagory-section ul li .sell-input-field .select-wrapper input.select-dropdown {
    width: 135px;
    padding-left: 10px;
  }
  .deal-catagory-section ul li .sell-input-field i {
    right: 12px;
  }
  .deal-catagory-section ul li .action-input-field .select-wrapper input.select-dropdown {
    width: 88px;
    padding-left: 10px;
  }
  .deal-catagory-section ul li .action-input-field i {
    right: 8px;
  }
  .deal-catagory-section ul li .datepicker-input-field {
    width: 62px;
    margin-right: 10px;
  }
  .deal-filtering-section ul li .search-input-field {
    width: 166px;
  }
  .deal-catagory-section ul li span.total-delas-number {
    display: block;
  }
  .deal-catagory-section ul li .datepicker-input-field i.keyboard-arrow {
    right: 2px;
  }
  .deal-catagory-section ul li span.total-value-ammount {
    margin-left: 0;
  }
  .card-with-wrapper {
    width: 25%;
  }
  .card-with-wrapper .title-card {
    margin: 0 0 0 -5px;
  }
  /* Responsive */
  .action-btn-dropdown.vehicle {
    /* max-width: 113px; */
    max-width: 135px;
    left: 0;
    font-size: 16px;
    line-height: 24px;
  }
  .action-btn-dropdown.vehicle .customFormControl {
    width: 100%;
  }
  .action-btn-dropdown.statusDropdown {
    max-width: 88px;
    left: 145px;
  }
  .pipeline-dropdown, .action-btn-dropdown, .datepicker-dropdown {}
  /* .datepicker-dropdown {
    left: 240px;
} */
  .datepicker-dropdown .MuiButton-root {
    padding: 9px 15px !important;
  }
}

.deal-catagory-section li .customFormControl {
  margin-top: 0px !important;
}

@media only screen and (max-width:1599px) {
  .deal_update .deal-catagory-section ul li {
    margin-right: 10px;
  }
  .deal_update .action-btn-dropdown.vehicle {
    max-width: 200px;
  }
  .deal_update .deal-catagory-section {
    max-width: calc(100% - 365px) !important;
    margin-right: 0 !important;
  }
  .deal_update .deal-filtering-section {
    width: 365px !important;
    margin-left: 0 !important;
  }
  .deal_update .deal-catagory-section ul li .total-value-ammount, .deal_update .deal-catagory-section ul li .total-delas-number {
    font-size: 13px;
  }
  .deal-filtering-section ul li .search-input-field {
    width: 166px;
  }
  .deal-catagory-section>ul {
    position: relative;
  }
  .pipeline-dropdown, .action-btn-dropdown, .datepicker-dropdown {
    /* position: absolute; */
    /* left: 0; */
    /* top: 55px; */
  }
  .action-btn-dropdown {
    left: 150px;
  }
  /* .datepicker-dropdown {
    left: 250px;
  } */
  .deal-catagory-section ul li {
    margin-right: 0;
  }
  .deal-catagory-section ul li span.total-delas-number {
    display: inline-block;
    position: relative;
  }
  .deal-catagory-section ul li span.total-delas-number::after {
    color: #133159;
  }
  .deal-filtering-section ul {
    position: relative;
  }
  /* .deal-filtering-section ul li.deal-actions-buttons a:not(.active-icon) {
    display: none;
  } */
  .deal-catagory-section, .deal-filtering-section {
    margin-bottom: 60px;
    margin-bottom: 45px;
  }
  .deal-filtering-section ul {
    position: relative;
  }
  /* .deal-filtering-section ul li.deal-actions-buttons a.active-icon {
    position: absolute;
    right: 0;
    top: 0;
  } */
  /* .deal-filtering-section ul li.search-area {
    position: absolute;
    top: 55px;
    right: 0;
    margin-right: 0;
  } */
  .deal-filtering-section ul li {
    margin-right: 13px;
  }
  .deal-filtering-section ul li:last-child {
    margin-right: 50px;
  }
  .card-with-wrapper {
    width: 30%;
  }
}

@media(max-width: 1366px) {
  .deal_update .steps .deal-count, .deal_update .steps .deal-amount {
    font-size: 8px;
  }
  .deal_update .action-btn-dropdown.vehicle {
    max-width: 150px;
  }
  body .action-btn-dropdown.statusDropdown {
    max-width: 90px;
  }
  .action-btn-dropdown .customFormControl.alt .trigger__select .MuiSelect-select {
    font-size: 14px;
  }
  .deal_update .deal-catagory-section ul li .total-value-ammount, .deal_update .deal-catagory-section ul li .total-delas-number {
    font-size: 12px;
  }
  .deal_update .deal-catagory-section ul li {
    margin-right: 6px;
  }
}

@media only screen and (max-width: 1199px) {
  .deal_update .deal-catagory-section, .deal_update .deal-filtering-section {
    padding: 0 !important;
  }
  
  .deal_update .deal-catagory-section {
    width: calc(100% - 345px) !important;
  }
  .deal_update .deal-filtering-section {
    width: 345px !important;
  }
  .card-with-wrapper {
    width: 48.5%;
  }
  .deal-actions-buttons {
    justify-content: space-between;
  }
  .action-btn {
    width: 15%;
  }
}

@media only screen and (max-width: 992px) {}

@media only screen and (max-width: 600px) {}