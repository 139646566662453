.paginationWrp{
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.left, .right{
  height: 40px;
  width: 40px;
  border-radius: 4px;
  border: 1px solid #ddd;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
}
.totalPages{
  height: 40px;
  min-width: 40px;
  border-radius: 4px;
  border: 1px solid #ddd;
  align-items: center;
  justify-content: center;
  display: flex;
}
.input{
  height: 40px !important;
  width: 80px !important;
  border-radius: 4px !important;
  border: 1px solid #ddd !important;
  align-items: center !important;
  justify-content: center !important;
  display: flex;
  margin: 0px !important;
  text-align: center;
  padding: 0px 5px !important;
  font-size: 13px !important;
}
.input:focus{
  box-shadow: unset !important;
}
.seprator{
  height: 40px;
  width: 10px;
  align-items: center;
  justify-content: center;
  display: flex;
  color: #ddd;
}
.disabled{
  cursor: not-allowed;
  opacity: 0.5;
}
.refresh{
  height: 40px !important;
  width: 80px !important;
  border-radius: 4px !important;
  border: 1px solid #ddd !important;
  align-items: center !important;
  justify-content: center !important;
  display: flex;
  margin: 0px !important;
  text-align: center;
  padding: 0px 5px !important;
  font-size: 13px !important;
  cursor: pointer;
  background-color: #6070ed;
  color: #fff;
}