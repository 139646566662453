

.DealModule-container-parent-class .deal-stage-dragable-container-v2{
    height: calc(100% - 57px);
    overflow: hidden;
}

.DealModule-container-parent-class .deal__update__card__wrapper__alt{
    transition: all .3s linear;
    height:100%;
    transition: all .3s linear;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    min-height: auto;

    gap: 10px;
}

.DealModule-container-parent-class .deal__actions__buttons__each__v2 + div {
    width: 100px!important;
}
.DealModule-container-parent-class .deal__update__card__container__alt{
    min-width: 270px;
}
.DealModule-container-parent-class .deal__update__card__wrapper__alt.deal__update__card__wrapper__alt__active{
    height: calc(100% - 80px)!important;
}

.DealModule-container-parent-class .deal__update__card__buttons__alt .deal-handle-buttons-each-v2{
    display: flex;
    height: 60px;
    /* flex: 1 1; */
} 
.DealModule-container-parent-class .deal__update__card__container__alt{
    width: 15%;
    flex: 1 1;
    border-radius: 0;
    transition: none;
    position: relative;
}
.DealModule-container-parent-class .each_stage_header_container_v2{
    margin: 10px 0;

}
.DealModule-container-parent-class .deal__top__lebel__info__v2{
    display: flex;
    justify-content: space-between;
    position: relative;
    height: 55px;
    margin: 0 8px 0 0;
    border-right: 3px solid #fff;
    align-items: center;
    width: 100%;
}
.DealModule-container-parent-class .deal__top__lebel__info__v2__active{
    /* background: rgb(44, 110, 98); */
   
}
.DealModule-container-parent-class .deal__top__lebel__info__v2.deal__top__lebel__info__v2__active:after{
    /* border-left-color: rgb(44, 110, 98); */
}
.DealModule-container-parent-class .deal__top__lebel__info__v2::before{
    border-top-width: 27.5px;
    border-bottom-width: 27.5px;
    position: absolute;
    top: 0;
    display: block;
    border-top: 28px solid transparent;
    border-bottom: 28px solid transparent;
    width: 0;
    height: 0;
    content: " ";
    left: 0px;
    border-left: 25px solid #fff;

}
.DealModule-container-parent-class .deal__update__card__container__alt:first-child .each_stage_header_container_v2 .deal__top__lebel__info__v2::before{
    display: none;

}
.DealModule-container-parent-class .deal__update__card__container__alt:last-child .each_stage_header_container_v2 .deal__top__lebel__info__v2::after{
    display: none;

}
.DealModule-container-parent-class .deal__update__card__container__alt:last-child .deal__top__lebel__info__plus__v2{
    margin: 0 10px 0 0;

}

.DealModule-container-parent-class .deal__top__lebel__info__v2::after{
    z-index: 1;
    right: -25px;
    border-left: 25px solid;
    position: absolute;
    top: 0;
    display: block;
    border-top: 45px solid transparent;
    border-bottom: 45px solid transparent;
    width: 0;
    height: 0;
    content: " ";
    border-top-width: 27.5px;
    border-bottom-width: 27.5px;

}

.DealModule-container-parent-class .deal__top__lebel__info__left__v2{
    padding-left: 35px;
    width: calc(100% - 35px);
    color: var(--white);
}
.DealModule-container-parent-class .deal__top__lebel__info__item__v2 span{
    font-size: 13px;
    line-height: 17px;
    color: var(--white);
    letter-spacing: 0.1px;
    margin-bottom: 5px;
    display: inline-block;
}
.DealModule-container-parent-class .deal__top__lebel__info__value__v2{
    font-size: 10px;
    font-weight: 700;
    line-height: 18px;
    color: var(--white);
    display: flex;
    align-items: center;
    grid-gap: 8px;
    letter-spacing: 0.1px;
}
.DealModule-container-parent-class .deal__top__lebel__info__plus__v2{
    height: 25px;
    width: 25px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
}
.DealModule-container-parent-class .deal__top__lebel__info__plus__v2 svg{
    font-size: 18px;
}
.DealModule-container-parent-class .deal__top__value__v2 svg{
    width: 10px;
    margin-left: 5px;
    display: inline-flex;
    height: 9px;
    margin-top: -2px;
}
.DealModule-container-parent-class .deal__top__value__v2{
    display: flex;
    align-items: center;
}
.DealModule-container-parent-class .deal__update__middle__wrapper__alt__v2{
    height: calc(100% - 57px);
    overflow: hidden;
}
/* .DealModule-container-parent-class .deal__update__drag__container__alt{
    height: calc(100% - 56px);
    max-height: calc(100% - 75px);
} */
.DealModule-container-parent-class .deal__update__drag__row__alt{
    margin: 0 8px 0 0;
    /* padding: 8px 13px 1px 8px; */
    padding: 8px 8px 1px 8px;
    background: #f6f7fb;
    border: 1px solid #e5e8ef;
    box-sizing: border-box;
    border-radius: 10px;
    /* overflow-y: auto; */
    flex-wrap: wrap;
    height: calc(100% - 56px);
    max-height: calc(100% - 75px);

    width: 100%;   
}

.DealModule-container-parent-class .each__deal__container__v2{
    position: relative;
    max-width: 100%;
    left: 5px;
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 6px 10px;
}
.DealModule-container-parent-class .each__deal__container__v2__left{
    position: relative;
    width: calc(100% - 40px);
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.DealModule-container-parent-class .each__deal__container__v2__left span{
    line-height: 20px;
    font-size: 12px;
    margin: 0!important;
    color: #333;
    font-weight: 500;
    letter-spacing: .01em;
    cursor: pointer;
}
.DealModule-container-parent-class .each__deal__container__v2__left p{
    line-height: 14px;
    margin: 5px 0 0 0;
    font-size: 10px;
    margin-bottom: 0;
    color: #99a6b9;
    letter-spacing: .01em;
    font-weight: 400;
}
.DealModule-container-parent-class .each__deal__container__v2__left a{
    width: auto;
    max-width: 85px;
    height: 20px;
    padding: 0 15px;
    font-size: 11px;
    line-height: 20px;
    margin-bottom: 0;
    border-radius: 80px;
    font-weight: 500;
    background-color: #27ae60;
    color: #fff;
    display: inline-block;
}
.DealModule-container-parent-class .each__deal__container__v2__right a{
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
}
.DealModule-container-parent-class .each__deal__container__v2__right i{
    font-size: 18px;
}
.DealModule-container-parent-class  .deal__update__drag__row__alt .update__dragbale__v2{
    /* height: 80px; */
    margin: 0 0 5px;
    border-radius: 10px;
    box-shadow: none;
    max-width: 100%;
    position: relative;
    background-color: var(--accent-color);
    width: 100%;
}



/* deal custom wrapper scroll bar */
.deal__custom__wrapper__scroll__bar__v2 {
  /* Thumb */
  /* scrollbar-face-color: #133159;
  scrollbar-shadow-color: #133159;
  scrollbar-highlight-color: #133159;
  scrollbar-3dlight-color: #133159;
  scrollbar-darkshadow-color: #133159; */
  /* Track */
  /* scrollbar-track-color: #ECF3FE; */
  /* Buttons */
  /* scrollbar-arrow-color: #133159; */
}

/* .deal__custom__wrapper__scroll__bar__v2 {
  background-color: #133159;
  width: 8px;
  border-radius: 10px;
} */

.deal__custom__wrapper__scroll__bar__v2::-webkit-scrollbar{
  background-color: #ECF3FE;
  width: 6px;
  border-radius: 10px;
}

.deal__custom__wrapper__scroll__bar__v2 {
  scrollbar-color: #133159 #ECF3FE;
  scrollbar-width: thin;
  border-radius: 10px;
}

.deal__custom__wrapper__scroll__bar__v2::-webkit-scrollbar {
  width: 6px;
  background-color: rgba(60, 126, 243, 0.1);
  border-radius: 10px;
}

.deal__custom__wrapper__scroll__bar__v2::-webkit-scrollbar-thumb {
    width: 6px;
  background-color: #133159;
  border-radius: 10px;
}

/* button */

.DealModule-container-parent-class .deal__update__card__buttons__alt .deal-handle-buttons-v2{
    display: flex;
    /* height: 40px; */
    height: 60px;
    /* grid-gap: 16px; */
    align-items: center;
} 

.DealModule-container-parent-class .deal__handle__buttons__each__v2 {
    flex: 1 1;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 5px;
    height: 100%;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    /* background: transparent !important; */
}
.DealModule-container-parent-class .deal__actions__buttons__each__v2 {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    /* position: absolute; */
    /* height: 40px; */
    height: 55px;
    width: 100%;
    justify-content: center;
    /* background: #fff; */
}
/* .DealModule-container-parent-class .deal__update__card__wrapper__alt__active .deal__actions__buttons__each__v2{
    background: #f00;
} */
.DealModule-container-parent-class .deal__actions__buttons__delete__v2{
    border: 1px solid var(--red);
    color: var(--red);
    border-radius: 6px;
    position: absolute;
}
.DealModule-container-parent-class .deal__actions__buttons__won__v2 {
    border: 2px dashed #6fcf97;
    /* color: #6fcf97; */
    color: #000;
    border-radius: 4px;
    position: absolute;
    font-size: 20px;
    background-color: #acffc6;
    font-weight: bolder;
}
.DealModule-container-parent-class .deal__actions__buttons__lost__v2 {
    /* border: 2px dashed var(--red); */
    border: 2px dashed #ff0001;
    /* color: var(--red); */
    color: #000;
    border-radius: 4px;
    position: absolute;
    font-size: 20px;
    background-color: #ffa8b2;
    font-weight: bolder;
}
.DealModule-container-parent-class .deal__actions__buttons__pipeline__v2 {
    border: 1px solid #ffb053;;
    color: #ffb053;
    border-radius: 6px;
    position: absolute;

}
.deal__actions__buttons__each__v2.deal__actions__buttons__pipeline__v2 svg {
    fill: #ffb053;
}


.DealModule-container-parent-class .deal__actions__buttons__each__v2 span {
    display: inline-flex;
}


/* empty container loader container */
.DealModule-container-parent-class .empty__container__loading__v2,
.DealModule-container-parent-class .empty__container__v2{
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
}
.DealModule-container-parent-class .empty__container__loading__v2 p,
.DealModule-container-parent-class .empty__container__v2 p{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: .01em;
    color: #7a8088;
}
.DealModule-container-parent-class .deal__update__card__buttons__alt{
    margin: 10px 0 0 0;
}
.DealModule-container-parent-class .load__more__each__card{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: .01em;
    color: #7a8088;
    display: flex;
    justify-content: center;
    align-items: center;
}


/* Scroll Bar */
.awesome__scroll_bar__deal__v2::-webkit-scrollbar {
    width: 6px;
}
.awesome__scroll_bar__deal__v2::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
    border-radius: 10px;
    background-color: var(--dark_blue);
}
.awesome__scroll_bar__deal__v2::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
    background-color: var(--purple);
}

.DealModule-container-parent-class .deal__update__card__container__alt {
    /* max-width: 210px; */
    /* max-width: 280px; */
    box-sizing: border-box;
}

@media only screen and (max-width: 1599px){
    .DealModule-container-parent-class .deal__update__card__container__alt {
        /* min-width: 210px;
        max-width: 210px; */
        min-width: 280px;
        /* max-width: 280px; */
    }

    .DealModule-container-parent-class .deal__update__middle__wrapper__alt__v2 {
        height: calc(100% - 66px);
    }
}





.deal__update__card__wrapper__alt__active .deal__update__card__wrapper__alt {
    height: calc(100% - 80px);
    margin-bottom: 23px;
    /* overflow: hidden; */
}

.deal__update__drag__row__alt .MuiSkeleton-text{
    transform: unset;
    margin-bottom: 5px;
    height: 18% !important;
}
.deal__update__card__wrapper__alt__active .deal__update__card__wrapper__alt{
    padding-bottom: 40px !important;
}

/* for arrow sign */
.deal__update__middle__wrapper__alt__v2{
  position: relative;
}
.left_arrow_sign, .right_arrow_sign{
  position: absolute;
  z-index: 99999999999;
  /* z-index: 1; */
  background-color: #7a80889e;
  padding: 35px 20px;
  top: 50%;
  cursor: all-scroll;
  display: none;
}
.show_arrow_sign{
  display: block !important;
}
.left_arrow_sign{
  left: 0px;
  border-radius: 0px 40px 40px 0px;
}
.right_arrow_sign{
  right: 0px;
  border-radius: 40px 0px 0px 40px;
}
.left_arrow_sign svg{
  transform: rotate(180deg);
}
.left_arrow_sign svg, .right_arrow_sign svg{
  display: flex;
  align-items: center;
  justify-content: center;
}
.deal__actions__buttons__each__v2 svg{display: inline-flex;}
