.datePickerWrapper {
    position: relative;
}

.datePickerWrapper .datePickerPreviewInputWrapper {
    position: relative;
}

.deal-report-filter .datePickerWrapper .datePickerPreviewInputWrapper svg {
    position: absolute;
    right: 12px;
    top: 18px;
    cursor: pointer;
}

.datePickerWrapper .datePickerSelectorWrapper {
    position: absolute;
    z-index: 9999;
    right: -120px;
    top: 70px;
    background-color: #fff;
    padding: 10px;
    box-shadow: -1px 1px 6px;
    border-radius: 8px;
    font-size: 12px;
}

.datePickerWrapper .datePickerPreviewInputWrapper .datePickerClear {
    position: absolute;
}

.datePickerWrapper .datePickerPreviewInputWrapper .datePickerClear path {
    stroke: #0c1038;
    cursor: pointer;
}

.datePickerWrapper .datePickerSelectorWrapper::after {
    content: "";
    position: absolute;
    top: -40px;
    left: 60%;
    border-width: 20px;
    border-style: solid;
    border-color: transparent transparent #68fafd transparent;
}

.datePickerWrapper .datePickerSelectorWrapper .datePickerClose{
    position: absolute;
    background-color: red;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    right: -15px;
    top: -16px;
    cursor: pointer;
}

.deal-report-filter .datePickerWrapper .datePickerPreviewInput {
    color: #133159a6 !important;
    width: 100% !important;
    min-width: 285px;
    height: 45px !important;
    margin-top: 4px !important;
    margin-bottom: 7px !important;
    border: 1px solid rgb(141, 148, 165) !important;
    background-color: #ffffff !important;
    box-sizing: border-box !important;
    border-radius: 4px !important;
    font-size: 16px !important;
    padding: 12px !important;
    line-height: 24px !important;
    font-weight: normal !important;
    cursor: pointer;
}

.datePickerWrapper .rdrCalendarWrapper{
    color: #000000;
    font-size: 12px;
}

.datePickerWrapper .rdrDateDisplayWrapper{
    background-color: rgb(239, 242, 247);
}

.datePickerWrapper .rdrDateDisplay{
    margin: 0.833em;
}

.datePickerWrapper .rdrDateDisplayItem{
    border-radius: 4px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 1px 2px 0 rgba(35, 57, 66, 0.21);
    border: 1px solid transparent;
}

.datePickerWrapper .rdrDateDisplayItem input{
    cursor: pointer;
    height: 2.5em;
    line-height: 2.5em;
    border: 0px;
    background: transparent;
    width: 100%;
    color: #849095;
    margin-bottom: 0px !important;
}

.datePickerWrapper input:focus {
    outline: 0 !important;
    box-shadow: none !important;
}

.datePickerWrapper .rdrDateDisplayItemActive{
    border-color: currentColor;
}

.datePickerWrapper .rdrDateDisplayItemActive input{
    color: #7d888d
}

.datePickerWrapper .rdrMonthAndYearWrapper {
    align-items: center;
    height: 60px;
    padding-top: 10px;
}

.datePickerWrapper .rdrMonthAndYearPickers{
    font-weight: 600;
}

.datePickerWrapper .rdrMonthAndYearPickers select{
    -moz-appearance: none;
    appearance: none;
    -webkit-appearance: none;
    border: 0;
    background: transparent;
    padding: 10px 30px 10px 10px;
    border-radius: 4px;
    outline: 0;
    color: #3e484f;
    background: url("data:image/svg+xml;utf8,<svg width='9px' height='6px' viewBox='0 0 9 6' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><g id='Artboard' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' transform='translate(-636.000000, -171.000000)' fill-opacity='0.368716033'><g id='input' transform='translate(172.000000, 37.000000)' fill='%230E242F' fill-rule='nonzero'><g id='Group-9' transform='translate(323.000000, 127.000000)'><path d='M142.280245,7.23952813 C141.987305,6.92353472 141.512432,6.92361662 141.219585,7.23971106 C140.926739,7.5558055 140.926815,8.06821394 141.219755,8.38420735 L145.498801,13 L149.780245,8.38162071 C150.073185,8.0656273 150.073261,7.55321886 149.780415,7.23712442 C149.487568,6.92102998 149.012695,6.92094808 148.719755,7.23694149 L145.498801,10.7113732 L142.280245,7.23952813 Z' id='arrow'></path></g></g></g></svg>") no-repeat;
    background-position: right 8px center;
    cursor: pointer;
    text-align: center
}

.datePickerWrapper .rdrMonthAndYearPickers select:hover{
    background-color: rgba(0,0,0,0.07);
}

.datePickerWrapper .rdrMonthPicker, .rdrYearPicker{
    margin: 0 5px
}

.datePickerWrapper .rdrNextPrevButton {
    display: block;
    width: 24px;
    height: 24px;
    margin: 0 0.833em;
    padding: 0;
    border: 0;
    border-radius: 5px;
    background: #EFF2F7
}

.datePickerWrapper .rdrNextPrevButton:hover{
    background: #E1E7F0;
}

.datePickerWrapper .rdrNextPrevButton i {
    display: block;
    width: 0;
    height: 0;
    padding: 0;
    text-align: center;
    border-style: solid;
    margin: auto;
    transform: translate(-3px, 0px);
}

.datePickerWrapper .rdrPprevButton i {
    border-width: 4px 6px 4px 4px;
    border-color: transparent rgb(52, 73, 94) transparent transparent;
    transform: translate(-3px, 0px);
}

.datePickerWrapper .rdrNextButton i {
    margin: 0 0 0 7px;
    border-width: 4px 4px 4px 6px;
    border-color: transparent transparent transparent rgb(52, 73, 94);
    transform: translate(3px, 0px);
}

.datePickerWrapper .rdrWeekDays {
    padding: 0 0.833em;
}

.datePickerWrapper .rdrMonth{
    padding: 0 0.833em 1.666em 0.833em;
}

.datePickerWrapper .rdrMonth .rdrWeekDays {
    padding: 0;
}

.datePickerWrapper .rdrMonths.rdrMonthsVertical .rdrMonth:first-child .rdrMonthName{
    display: none;
}

.datePickerWrapper .rdrWeekDay {
    font-weight: 400;
    line-height: 2.667em;
    color: rgb(132, 144, 149);
}

.datePickerWrapper .rdrDay {
    background: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 0;
    padding: 0;
    line-height: 3.000em;
    height: 3.000em;
    text-align: center;
    color: #1d2429
}

.datePickerWrapper .rdrDay:focus {
    outline: 0;
}

.datePickerWrapper .rdrDayNumber {
    outline: 0;
    font-weight: 300;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    top: 5px;
    bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.datePickerWrapper .rdrDayToday .rdrDayNumber span{
    font-weight: 500
}

.datePickerWrapper .rdrDayToday .rdrDayNumber span:after{
    content: '';
    position: absolute;
    bottom: 4px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 18px;
    height: 2px;
    border-radius: 2px;
    background: #3d91ff;
}

.datePickerWrapper .rdrDayToday:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span:after,.datePickerWrapper .rdrDayToday:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span:after,.datePickerWrapper .rdrDayToday:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span:after,.datePickerWrapper .rdrDayToday:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span:after{
    background: #fff;
}

.datePickerWrapper .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,.rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,.rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span,.rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span{
    color: rgba(255, 255, 255, 0.85);
}

.datePickerWrapper .rdrSelected, .datePickerWrapper .rdrInRange, .datePickerWrapper .rdrStartEdge, .datePickerWrapper .rdrEndEdge{
    background: currentColor;
    position: absolute;
    top: 5px;
    left: 0;
    right: 0;
    bottom: 5px;
}

.datePickerWrapper .rdrSelected{
    left: 2px;
    right: 2px;
}

.datePickerWrapper .rdrInRange{}

.datePickerWrapper .rdrStartEdge{
    border-top-left-radius: 1.042em;
    border-bottom-left-radius: 1.042em;
    left: 2px;
}

.datePickerWrapper .rdrEndEdge{
    border-top-right-radius: 1.042em;
    border-bottom-right-radius: 1.042em;
    right: 2px;
}

.datePickerWrapper .rdrSelected{
    border-radius: 1.042em;
}

.datePickerWrapper .rdrDayStartOfMonth .rdrInRange, .datePickerWrapper .rdrDayStartOfMonth .rdrEndEdge, .rdrDayStartOfWeek .rdrInRange, .datePickerWrapper .rdrDayStartOfWeek .rdrEndEdge{
    border-top-left-radius: 1.042em;
    border-bottom-left-radius: 1.042em;
    left: 2px;
}

.datePickerWrapper .rdrDayEndOfMonth .rdrInRange,  .datePickerWrapper .rdrDayEndOfMonth .rdrStartEdge,  .datePickerWrapper .rdrDayEndOfWeek .rdrInRange,  .datePickerWrapper .rdrDayEndOfWeek .rdrStartEdge{
    border-top-right-radius: 1.042em;
    border-bottom-right-radius: 1.042em;
    right: 2px;
}

.datePickerWrapper .rdrDayStartOfMonth .rdrDayInPreview, .datePickerWrapper .rdrDayStartOfMonth .rdrDayEndPreview, .datePickerWrapper .rdrDayStartOfWeek .rdrDayInPreview, .datePickerWrapper .rdrDayStartOfWeek .rdrDayEndPreview{
    border-top-left-radius: 1.333em;
    border-bottom-left-radius: 1.333em;
    border-left-width: 1px;
    left: 0px;
}

.datePickerWrapper .rdrDayEndOfMonth .rdrDayInPreview, .datePickerWrapper .rdrDayEndOfMonth .rdrDayStartPreview, .datePickerWrapper .rdrDayEndOfWeek .rdrDayInPreview, .datePickerWrapper .rdrDayEndOfWeek .rdrDayStartPreview{
    border-top-right-radius: 1.333em;
    border-bottom-right-radius: 1.333em;
    border-right-width: 1px;
    right: 0px;
}

.datePickerWrapper .rdrDayStartPreview, .datePickerWrapper .rdrDayInPreview, .datePickerWrapper .rdrDayEndPreview{
    background: rgba(255, 255, 255, 0.09);
    position: absolute;
    top: 3px;
    left: 0px;
    right: 0px;
    bottom: 3px;
    pointer-events: none;
    border: 0px solid currentColor;
    z-index: 1;
}

.datePickerWrapper .rdrDayStartPreview{
    border-top-width: 1px;
    border-left-width: 1px;
    border-bottom-width: 1px;
    border-top-left-radius: 1.333em;
    border-bottom-left-radius: 1.333em;
    left: 0px;
}

.datePickerWrapper .rdrDayInPreview{
    border-top-width: 1px;
    border-bottom-width: 1px;
}

.datePickerWrapper .rdrDayEndPreview{
    border-top-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 1px;
    border-top-right-radius: 1.333em;
    border-bottom-right-radius: 1.333em;
    right: 2px;
    right: 0px;
}

.datePickerWrapper .rdrDefinedRangesWrapper{
    font-size: 12px;
    width: 226px;
    border-right: solid 1px #eff2f7;
    background: #fff;
    /* display: none; */
}

.datePickerWrapper .rdrDefinedRangesWrapper .rdrStaticRangeSelected{
    color: currentColor;
    font-weight: 600;
}

.datePickerWrapper .rdrStaticRange{
    border: 0;
    cursor: pointer;
    display: block;
    outline: 0;
    border-bottom: 1px solid #eff2f7;
    padding: 0;
    background: #fff
}

.datePickerWrapper .rdrStaticRange:hover .rdrStaticRangeLabel,.datePickerWrapper .rdrStaticRange:focus .rdrStaticRangeLabel{
    background: #eff2f7;
}

.datePickerWrapper .rdrStaticRangeLabel{
    display: block;
    outline: 0;
    line-height: 18px;
    padding: 10px 20px;
    text-align: left;
}

.datePickerWrapper .rdrInputRanges{
    padding: 10px 0;
}

.datePickerWrapper .rdrInputRange{
    align-items: center;
    padding: 5px 20px;
}

.datePickerWrapper .rdrInputRangeInput{
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 4px;
    text-align: center;
    border: solid 1px rgb(222, 231, 235);
    margin-right: 10px;
    margin-bottom: 0px !important;
    color: rgb(108, 118, 122)
}

.datePickerWrapper .rdrInputRangeInput:focus, .datePickerWrapper .rdrInputRangeInput:hover{
    border-color: rgb(180, 191, 196);
    outline: 0;
    color: #333;
}

.datePickerWrapper .rdrCalendarWrapper:not(.rdrDateRangeWrapper) .rdrDayHovered .rdrDayNumber:after{
    content: '';
    border: 1px solid currentColor;
    border-radius: 1.333em;
    position: absolute;
    top: -2px;
    bottom: -2px;
    left: 0px;
    right: 0px;
    background: transparent;
}

.datePickerWrapper .rdrDayPassive{
    pointer-events: none;
}

.datePickerWrapper .rdrDayPassive .rdrDayNumber span{
    color: #d5dce0;
}

.datePickerWrapper .rdrDayPassive .rdrInRange, .datePickerWrapper .rdrDayPassive .rdrStartEdge, .datePickerWrapper .rdrDayPassive .rdrEndEdge, .rdrDayPassive .rdrSelected, .datePickerWrapper .rdrDayPassive .rdrDayStartPreview, .datePickerWrapper .rdrDayPassive .rdrDayInPreview, .datePickerWrapper .rdrDayPassive .rdrDayEndPreview{
    display: none;
}

.datePickerWrapper .rdrDayDisabled {
    background-color: rgb(248, 248, 248);
}

.datePickerWrapper .rdrDayDisabled .rdrDayNumber span{
    color: #aeb9bf;
}

.datePickerWrapper .rdrDayDisabled .rdrInRange, .datePickerWrapper .rdrDayDisabled .rdrStartEdge, .datePickerWrapper .rdrDayDisabled .rdrEndEdge, .datePickerWrapper .rdrDayDisabled .rdrSelected, .datePickerWrapper .rdrDayDisabled .rdrDayStartPreview, .datePickerWrapper .rdrDayDisabled .rdrDayInPreview, .datePickerWrapper .rdrDayDisabled .rdrDayEndPreview{
    filter: grayscale(100%) opacity(60%);
}

.datePickerWrapper .rdrMonthName{
    text-align: left;
    font-weight: 600;
    color: #849095;
    padding: 0.833em;
}
