.appMainWrp{
  width: 100%;
  height: 100%;
  display: flex;
  box-sizing: border-box;
  margin-top: 10px;
  flex-direction: column;
}
.width50PercentWrp{
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  gap: 20px;
  flex: 1 1;
}