.rowWrp{
  width: 100%;
  min-height: 60px;
  border-bottom: 1px solid #ddd;
  display: flex;
}

.dealValue_Tooltip{
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  letter-spacing: 0.6px;
  white-space: nowrap;
}

.rowWrp:last-child{
  border-bottom: 0px;
}
.contactWrp, .stageWrp{
  padding: 5px 10px 5px 5px;
  /* width: calc((100% - 690px)/2); */
  min-width: 250px;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.truncate{
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.nameText{
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.035px;
  color: #1C1B1F;
}
.text{
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.035px;
}
.addressText{
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.035px;
  margin-top: 10px;
  color: #949DB2;
}
.emptyText{
  font-size: 10px;
  font-weight: 500;
  font-style: italic;
  letter-spacing: 0.035px;
  color: #949DB2;
}
.dealValueWrp{
  width: 180px;
  min-width: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.timeWrp{
  width: 230px;
  min-width: 230px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 10px;
}
.linkText{
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.035px;
  text-decoration: underline;
  color: blue;
  cursor: pointer;
  text-align: left;
  margin-right: auto;
}
.separator{
  width: 100%;
  height: 1px;
  background-color: #ddd;
  display: flex;
  margin: 5px;
}
.actionWrp{
  width: 280px;
  min-width: 280px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 10px;
  gap: 5px;
}
.actionIcon{
  cursor: pointer;
}
.stageTitle{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2; 
  -webkit-box-orient: vertical;
}