.circular-container {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins', serif;
    margin-top: 15px;
}

.circular-container .progress-circle {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.circular-container .progress-circle::before {
    content: '';
    position: absolute;
    width: 165px;
    height: 165px;
    border-radius: 50%;
    background: #fff;
    display: block;
}

.circular-container .progress-text {
    position: absolute;
    text-align: center;
}

.circular-container .label {
    display: block;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.5);
}

.circular-container .amount {
    display: block;
    font-size: 30px;
    font-weight: 600;
    color: #222;
}

.circular-container .complete {
    color: #d72121;
}

.circular-container .exceeded {
    color: #1826b6;
}

