.overlay{
  background-color: #00000080;
}
.mainWrp{
  width: 400px;
  max-height: 450px;
  display: flex;
  background-color: #fff;
  border-radius: 7px;
  flex-direction: column;
}
.header{
  width: 100%;
  min-height: 40px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;
}
.bodyWrp{
  width: 100%;
  height: auto;
  overflow: hidden;
  overflow-y: auto;
  flex-grow: 1;
  box-sizing: border-box;
  padding: 10px 20px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.footerWrp{
  width: 100%;
  min-height: 60px;
  display: flex;
  align-items: center;
  /* justify-content: flex-end; */
  justify-content: center;
  padding: 10px 20px;
  box-sizing: border-box;
  gap: 20px;
}
.close{
  cursor: pointer;
  display: inline-flex;
}
.button{
  padding: 7px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.6px;
  border-radius: 7px;
}
.cancelButton{
  color: #133159;
  background-color: #b2b2b2;
}
.submitButton{
  color: #fff;
  background-color: #006df5;
}
.bodyTitle{
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.7px;
  color: #133159;
  text-align: center;
}
.descriptionText{
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: #133159;
}
.highlightWrp{
  width: 100%;
  display: none;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 7px;
  gap: 10px;
  margin: 20px 0px;
}
.highlightWrpWarning{
  background-color: #fdf6b2;
}
.highlightBody{
  display: flex;
  flex-direction: column;
  gap: 7px;
}
.titleWarning{
  color: #8e4b10;
}
.submitButtonWarning{
  /* color: #8e4b10;
  background-color: #fdf6b2; */
  color: #ffffff;
  background-color: #ff0000;
}