.mainWrp {
  display: flex;
}
.defaultMenuLabel {
  display: flex;
  align-items: center;
  justify-self: center;
}
.defaultMenuLabel svg {
  display: inline-flex;
}
.menuListWrp {
  padding: 11px 10px !important;
}
.menuItem {
  display: flex;
  grid-gap: 8px;
  font-size: 14px;
  color: #546376;
  padding: 5px 2px;
  margin-bottom: 2px;
  border-radius: 4px;
  align-items: center;
  cursor: pointer;
}
.menuItem:hover {
  background: rgba(28, 27, 31, 0.08);
}
.menuItem span {
  display: inline-flex;
}
.noOption {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: hidden;
  padding: 0px;
  margin: 0px;
}
