.tableHeader{
  width: 100%;
  height: 60px;
  display: flex;
  border-bottom: 1px solid #ddd;
  align-items: center;
  gap: 5px;
  justify-content: space-between;
  overflow: hidden;
  overflow-x: auto;
}
.headerEach{
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 5px;
  position: relative;
  gap: 5px;
}
.eachSpan{
  height: 100%;
  display: flex;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.03px;
  align-items: center;
}
.small{
  font-weight: normal;
  font-size: 10px;
  letter-spacing: unset;
  display: flex;
  height: 100%;
  align-items: center;
  margin-top: 4px;
}
.middle{
  justify-content: center !important;
}
.width280{
  min-width: 280px !important;
  width: 280px !important;
}
.width230{
  min-width: 230px !important;
  width: 230px !important;
}
.width180{
  min-width: 180px !important;
  width: 180px !important;
}
.info{
  /* width: calc((100% - 690px)/2); */
  min-width: 250px;
  flex: 1;
}
.tableHeader .headerEach + .headerEach::before{
  content: '';
  position: absolute;
  left: -4px;
  width: 1px;
  height: 60%;
  background: #ddd;
}