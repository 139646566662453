.global__add__deal__wrapper .modalBody {
  padding: 24px !important;
}
.global__add__deal__wrapper
  .global_add_deal_module_container
  .crate__add__deal__btn__container {
  border-radius: 4px;

  color: #fff;
}
.global__add__deal__wrapper
  .global_add_deal_module_container
  .crate__add__deal__btn__wrapper__create {
  text-align: left;
  margin-bottom: 20px;
}
.global__add__deal__wrapper
  .global_add_deal_module_container
  .crate__add__deal__btn__wrapper__back {
  text-align: right;
  margin-bottom: 8px;
}

.global__add__deal__wrapper
  .global_add_deal_module_container
  .crate__add__deal__btn__container
  svg
  path {
  fill: #fff;
}
.global__add__deal__wrapper
  .global_add_deal_module_container
  .crate__add__deal__btn__container
  span {
  display: flex;
  align-items: center;
  grid-gap: 4px;
}
.global__add__deal__wrapper
  .select-existing-contact-add-deal
  .global_new_deal_search_wr {
  position: relative;
  margin-bottom: 12px;
}
.global__add__deal__wrapper .global__lead__search__required {
  position: absolute;
  top: -9px;
  right: -3px;
  color: var(--red);
  font-size: 18px;
  z-index: 2;
}
.global__add__deal__wrapper
  .global_add_deal_module_container
  .global-search-box-wrapper {
  width: 100% !important;
}
.global__add__deal__wrapper
  .global_add_deal_module_container
  .add__deal__label__title {
  font-size: 14px;
  color: var(--dark_blue);
  margin-bottom: 8px;
}
.global__add__deal__wrapper
  .global_add_deal_module_container
  .add__deal__text__field {
  border: 1px solid var(--gray_dark);
  box-sizing: border-box;
  padding: 0 14px;
  height: 40px;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: #a6acbe;
  margin: 0;
  padding-right: 30px !important;
  background-color: #fff;
  border-radius: 4px;
}
.global__add__deal__wrapper
  .global_add_deal_module_container
  .global__search__box__wr
  input[type="search"] {
  border-radius: 4px !important;
}
.global__add__deal__wrapper
  .global_add_deal_module_container
  .ppc__select_owner
  .MuiSelect-select {
  padding-top: 8px !important;
}

.global__add__deal__wrapper
  .global_add_deal_module_container
  .droupdown__helper
  .second__step__area__select {
  height: 40px !important;
}
.global__add__deal__wrapper
  .global_add_deal_module_container
  .deal__single__section__wr__row_1,
.global__add__deal__wrapper
  .global_add_deal_module_container
  .deal__single__section__wr__row_2,
.global__add__deal__wrapper
  .global_add_deal_module_container
  .deal__single__section__wr__row_3 {
  padding-bottom: 12px;
}

.global__add__deal__wrapper
  .global_add_deal_module_container
  .deal__value__with__estimate__date {
  display: flex;
  grid-gap: 16px;
}
.global__add__deal__wrapper
  .global_add_deal_module_container
  .deal__value__with__estimate__1 {
  flex: 1;
}
.global__add__deal__wrapper
  .global_add_deal_module_container
  .deal__value__with__estimate__2 {
  flex: 1;
}
.global__add__deal__wrapper
  .global_add_deal_module_container
  .add__deal__text__field__datePicker
  input {
  height: 39px !important;
  color: #a6acbe;
  padding-left: 0 !important;
}
.global__add__deal__wrapper .global_add_deal_module_container input:focus {
  border-color: var(--gray_dark) !important;
  box-shadow: none !important;
}
.global__add__deal__wrapper
  .global_add_deal_module_container
  .add__deal__text__field__datePicker
  .MuiInputAdornment-positionEnd {
  margin-left: 22px !important;
}
.global__add__deal__wrapper
  .global_add_deal_module_container
  .add__deal__text__field__datePicker {
  padding-right: 0 !important;
}

.global__add__deal__wrapper
  .global_add_deal_module_container
  .add__bottom__btn__container {
  border-radius: 4px;
  margin: 24px 0 0 0;
}
.global__add__deal__wrapper
  .global_add_deal_module_container
  .top-bar-search-result {
  top: -106px !important;
}
.global__add__deal__wrapper
  .global_add_deal_module_container
  .empty__loading__container {
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.global__add__deal__wrapper
  .global_add_deal_module_container
  .add__deal__text__field__datePicker
  button:focus {
  background-color: rgba(0, 0, 0, 0) !important;
}

.global__add__deal__wrapper
  .global_add_deal_module_container
  .add__deal__lead__source__wrapper
  .MuiFormControl-root {
  width: 100%;
}
.global__add__deal__wrapper
  .global_add_deal_module_container
  .add__deal__lead__source__wrapper
  .MuiFormControl-root
  .MuiInputBase-root {
  max-width: 100% !important;
}

.global__add__deal__wrapper
  .global_add_deal_module_container
  .add__deal__lead__source__wrapper
  .MuiInputBase-root
  .MuiSelect-select {
  padding: 7px 14px !important;
}
.global__add__deal__wrapper
  .global_add_deal_module_container
  .add__deal__lead__source__wrapper
  .MuiInputBase-root
  .MuiSelect-select:focus {
  border-radius: 4px !important;
}

.global__add__deal__wrapper
  .global_add_deal_module_container
  .lead__search__again__btn {
  border: 1px solid var(--direct_mail_green);
  background: var(--direct_mail_green);
  padding: 8px 12px;
  margin: 6px 0 14px 0;
  color: #fff !important;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
}

.global__add__deal__wrapper
  .global_add_deal_module_container
  .crate__add__deal__group__wrapper {
  display: flex;
  grid-gap: 16px;
}
.global__add__deal__wrapper
  .global_add_deal_module_container
  .crate__add__deal__group__wrapper
  .deal__single__section__wr__row_3 {
  flex: 1;
}

.global__add__deal__wrapper #top-search-result {
  top: 42px !important;
  width: 98%;
}




