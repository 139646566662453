.mainWrp{
  /* height: 100%; */
  /* width: 100%; */
  background-color: #ddd;
  border-radius: 4px;
  /* padding: 10px; */
  display: flex;
  overflow: hidden;

  /* height: calc(100vh - 20px); */
  height: calc(100vh - 190px);
  width: calc(100% - 20px);
  justify-content: center;
  margin: 0 auto;
  margin-top: 10px;
}
.tableWrp{
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  overflow: hidden;
  flex-direction: column;
}
.tableBody{
  width: 100%;
  height: calc(100% - 122px);
  overflow: hidden;
  overflow-x: auto;
  margin-top: 10px;
}
.loadingWrp{
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
}
.loadingText{
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-align: center;
  text-transform: capitalize;
}
/* global scroll bar for pdad */
.pdad_scroll_bar{
  /* height: 100%; */
  padding-bottom: 4px;
  overflow-y: auto;
}
.pdad_scroll_bar::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}
.pdad_scroll_bar::-webkit-scrollbar-track {
  background: rgba(60, 126, 243, 0.1);
  border-radius: 4px;
}
.pdad_scroll_bar::-webkit-scrollbar {
  scrollbar-width: thin;
}
.pdad_scroll_bar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #949DB2;
  box-sizing: border-box;
}
.pdad_scroll_bar::-webkit-scrollbar-track {
  background: #F4F5F7;
  box-shadow: inset 1px 0px 0px rgba(148, 157, 178, 0.12);
  -webkit-box-shadow: inset 1px 0px 0px rgba(148, 157, 178, 0.12);
}