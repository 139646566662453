.pipeline-drop-down {
  width: 300px;
  display: flex;
  background: #efefef;
  padding: 5px 15px;
  border-radius: 5px;
  margin-left: auto;
  cursor: pointer;
}
.pipeline-drop-down-alt {
  margin-left: unset !important;
  width: 350px;
}
.pipeline-drop-down .drop-down-wrp {
  flex: 1;
}
.pipeline-drop-down .drop-down-wrp .drop-down {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pipeline-drop-down .drop-down-wrp .drop-down .label-wrp {
  color: rgba(19, 49, 89, 0.65);
  font-family: Poppins;
  font-weight: 400 !important;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  gap: 10px;
  width: 230px;
}
.pipeline-drop-down .drop-down-wrp .drop-down .label-wrp > span {
  word-break: break-word;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.pipeline-drop-down .drop-down-wrp .drop-down .label-wrp > span small {
  margin-left: 10px;
}

/* custom confirmation alert class */
.custom-confirm-button {
  background-color: #4666f7 !important;
  border-radius: 10px !important;
  padding: 7px 45px !important;
  color: #fff !important;
}
.custom-cancel-button {
  background-color: #ff004a !important;
  border-radius: 10px !important;
  padding: 7px 45px !important;
  color: #fff !important;
}
.titleCustomClass {
  text-align: center !important;
}
