.dealWrp{
  min-width: 250px;
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  height: 120px;
  border-radius: 4px;
  cursor: grab;
  margin-bottom: 7px;
  border: 1px solid #d0caca;
  border-top: 3px solid #d0caca;
}
.dealWrp:hover{
  border-top: 3px solid #333;
}
.top{
  width: 100%;
  height: auto;
  display: flex;
  flex-grow: 1;
}
.bottom{
  width: 100%;
  min-height: 35px;
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  box-sizing: border-box;
  /* border-top-left-radius: 7px; */
  /* border-top-right-radius: 7px; */
  /* border-top: 1px solid #FFF; */
  /* box-shadow: inset 0px 11px 8px -10px #FFF;  */
  border-radius: 0px 0px 4px 4px;
}
.rightSide{
  height: 100%;
  min-width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.rightSide svg{
  cursor: pointer;
}
.rightSide svg:hover{
  height: 28px;
  width: 28px;
  transition: all 0.1s ease-in-out;
}
.leftSide{
  max-width: calc(100% - 40px);
  height: 100%;
  flex-grow: 1;
  display: flex;
  padding: 3px 5px;
  box-sizing: border-box;
  flex-direction: column;
  gap: 5px;
  position: relative;
}
.contactInfo{
  font-size: 13px;
  font-weight: 500;
  color: #1c1b1f;
  letter-spacing: 0.35px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}
.companyText{
  font-size: 12px;
  font-weight: 400;
  color: #546376;
  letter-spacing: 0.35px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.noCompany{
  font-style: italic;
}
.dateWrp{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}
.dateText{
  font-size: 12px;
  font-size: 600;
  letter-spacing: 0.6px;
  padding: 4px 5px;
  border-radius: 7px;
  color: #fff;
}
.dateRotateIcon{
  display: inline-flex;
  cursor: pointer;
}
.dateRotateIcon:hover svg{
  fill: #0759F3;
}
.dealValue{
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  text-align: center;
  letter-spacing: 0.6px;
  border-right: 2px solid #ddd;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dealValue_Tooltip{
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  letter-spacing: 0.6px;
  white-space: nowrap;
}

.actionWrp{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-left: 3px;
}
.icon{
  cursor: pointer;
  padding: 1px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon svg{
  fill: #FFF;
}
.icon:hover svg{
  fill: #adb3bd;
  transform: scale(1.3);
  transition: all 0.3s ease-in-out;
}
.editOption{
  position: absolute;
  top: 1px;
  right: -30px;
  padding: 2px 5px;
  border-radius: 4px;
  cursor: pointer;
  color: #fff;
  background-color: #0759F3;
  font-size: 10px;
  display: none;
}
.top:hover .editOption{
  display: block !important;
  transition: all 0.2s ease-in-out;
}
.colorItem{
  width: 30px;
  height: 30px;
  border: 1px solid #fff;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: inset 0px 11px 8px -10px #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
}

.recycledChip {
  font-size: 12px;
  border: 1px solid #0759F3;
  border-radius: 7px;
  padding: 3px 5px;
  color: #0759F3;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
}

.recycledChip svg {
  color: #0759F3;
}