.global__edit__deal__container__v2 .edit__deal__title__input__field{
    font-style: normal;
    font-weight: normal;
    font-size: 16px !important;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: #8896A8;
    padding-left: 12px !important;
    border: 1px solid #E5E8EF !important;
    box-sizing: border-box !important;
    border-radius: 5px !important;
    background: #FFFFFF !important;
    margin: 0px !important;
    position: unset !important;
    box-shadow: unset !important;
}
.global__edit__deal__container__v2 .global__edit__deal__container__select__v2 .MuiInputBase-root{
  padding: 10px;
}
.global__edit__deal__container__v2  .global__edit__deal__container__btn{
  float:right;
  padding:10px 16px;
  cursor:pointer;
  background: var(--accent-color);
  color: #fff;
  border: 0;
  border-radius: 4px;
  margin-top: 20px;
  display: flex;
    grid-gap: 11px;
    align-items: center;

}
.global__edit__deal__container__v2  .global__edit__deal__container__btn .edit__deal__button{
  display: flex;
}
.global__edit__deal__container__v2 .global__edit__deal__container__margintop{
  margin-top: 12px;
}
.global__edit__deal__container__v2 .global__edit__deal__container__select__v2 .MuiSelect-select:focus{
    margin-left: -11px !important;
    margin-top: -6px;
    padding: 6px 13px;
    background: transparent !important;
    height: auto !important;
}
.global__edit__deal__container__v2 .global__edit__deal__container__select__v2 .second__step__area__select{
  background: #fff !important;
}
.global__edit__deal__container__v2 .global__edit__deal__container__select__v2 .second__step__area__select .MuiSelect-icon{
  top: calc(50% - 20px) !important;
}
.global__edit__deal__container__v2 .global__edit__deal__container__date__v2{
  display: flex;
  justify-content: space-between;
  grid-gap: 16px;

}
.global__edit__deal__container__v2 .global__edit__deal__container__date__v2 .global__edit__deal__container__date__flex{
  flex: 1;
}
.global__edit__deal__container__v2 .global__edit__deal__container__date__v2 .global__edit__deal__container__date__flex .MuiFormControl-root{
    width: 100%;
    position: relative;
}
.global__edit__deal__container__v2 .global__edit__deal__container__date__v2 .global__edit__deal__container__date__flex .MuiInputAdornment-root{
  position: absolute;
  right: 12px;
}
.global__edit__deal__container__v2 .global__edit__deal__container__date__v2 .global__edit__deal__container__date__flex .MuiInputBase-root{
  position: relative;
}
.global__edit__deal__container__v2 .global__edit__deal__container__date__v2 input,
.global__edit__deal__container__v2 .global__edit__deal__container__dateinput__v2{
  font-style: normal;
    font-weight: normal;
    font-size: 16px !important;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: #8896A8;
    padding-left: 12px !important;
    border: 1px solid #E5E8EF !important;
    box-sizing: border-box !important;
    border-radius: 5px !important;
    background: #FFFFFF !important;
    margin: 0px !important;
    position: unset !important;
    box-shadow: unset !important;
}