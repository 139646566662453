.mainWrp{
  width: 100%;
  height: 500px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  background-color: #f3f4f5;
  border-radius: 4px;
  padding: 5px;
}
.headerWrp{
  width: 100%;
  height: 60px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  background-color: #ddd;
  margin-bottom: 20px;
  border-radius: 4px;
}
.graphWrp{
  height: calc(100% - 60px);
  width: 100%;
  display: flex;
  position: relative;
}
.graphWrpResponsive{
  /* height: calc(100% - 60px); */
  height: 100%;
  width: 100%;
  display: flex;
}
.headerText{
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-transform: capitalize;
}
.loadingWrp{
  height: calc(100% - 60px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
}
.filterWrp{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 10px;
  gap: 20px;
}
.timeFilterWrp{
  overflow: hidden !important;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 0px !important;
}
.timeFilterLabelWrp{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid #f3f4f5;
  padding: 5px 10px;
}
.timeFilterOption{
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  padding: 3px 15px;
}
.timeFilterOption:hover{
  background-color: #f3f4f5;
}
.emptyScreen{
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  font-size: 18px;
  font-weight: 600;
  background-color: #00000038;
}
.tooltipWrp{
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 7px;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.tooltipTimeWrp{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.tooltipTimeLabel{
  display: flex;
  align-items: center;
}
.tooltipTimeText{
  display: flex;
  align-items: center;
}
.tooltipDesWrp{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 3px;
}
.tooltipContact{
  color: #8884d8 !important;
  font-weight: 600;
}
.tooltipValue{
  color: #82ca9d !important;
  font-weight: 600;
}